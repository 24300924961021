import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Card,
  IconButton,
  Typography,
  CardActions,
  CardContent,
} from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 64,
    width: 64,
  },
}));

export default function MediaControlCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  
  function handleClick(){
    props.handleClick();
  }

  return (
    <Card style={{ height: 320, borderRadius: 20 }}>
      <CardContent className={classes.content}>
        <Typography component="h5" variant="h5">
          {props.podcast?.title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Author name
        </Typography>
      </CardContent>
      <CardContent>{props.podcast?.description}</CardContent>
      <CardActions style={{ textAlign: "center", display: "block" }}>
        <IconButton aria-label="play/pause" onClick={handleClick}>
          {props.playing ? (
            <PlayArrowIcon className={classes.icon} />
          ) : (
            <PauseIcon className={classes.icon} />
          )}
        </IconButton>
      </CardActions>
    </Card>
  );
}
