import {
  writeInt16,
  writeInt32,
  writeString,
  writeAudioBuffer,
} from "../../../../utils/tools";
import { invokeSaveAsDialog } from "recordrtc";

export default class Replay {
  data;
  soundBank;
  context;
  nbBlock;
  blob;
  audioElement;
  duration;

  constructor(data) {
    this.data = data;
    this.audioElement = document.createElement("audio");
    //document.body.appendChild(this.audioElement);
  }

  setData(data) {
    this.data = data;
    this.load(data);
  }

  setDuration(duration) {
    this.duration = duration > 1 ? duration : 1;
    this.update();
  }

  getDuration() {
    return this.duration;
  }

  play() {
    this.audioElement.play();
  }

  pause() {
    this.audioElement.pause();
  }

  stop() {
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
  }

  updateBlockStart(id, start) {
    for (const b in this.soundBank) {
      if (id === this.soundBank[b].id) {
        this.soundBank[b].defaultStart = start;
      }
    }
    this.update();
  }

  updateConfig(layers) {
    for (const layer of layers) {
      for (const b in this.soundBank) {
        if (layer.id === this.soundBank[b].config.id) {
          const { id, volume, audio } = layer;
          this.soundBank[b].config = { id, volume, audio };
        }
      }
    }
    this.update();
  }

  update() {
    const offlineCtx = new OfflineAudioContext(2, 44100 * this.duration, 44100);
    for (const b in this.soundBank) {
      const sourceOffline = this.createBufferSource(
        offlineCtx,
        this.soundBank[b]
      );
      sourceOffline.connect(offlineCtx.destination);
    }
    offlineCtx.startRendering().then(this.onRendered.bind(this));
  }

  onRendered(renderedBuffer) {
    const currentTime = this.audioElement.currentTime;
    const wavData = this.createWaveFileData(renderedBuffer);
    this.blob = new Blob([wavData], { type: "audio/wav" });
    this.audioElement.src = URL.createObjectURL(this.blob);
    setTimeout(
      function () {
        this.seek(currentTime);
      }.bind(this),
      300
    );
  }

  createBufferSource(context, sound) {
    const source = context.createBufferSource();
    source.buffer = sound.buffer;

    if (sound.config.audio) {
      const start = sound.defaultStart;
      // If sound is not yet played
      if (start > 0) {
        source.start(start);
      } else {
        source.start(0);
      }
      source.connect(context.destination);
    }

    source.defaultStart = sound.defaultStart;
    source.defaultDuration = sound.defaultDuration;
    source.config = sound.config;
    source.id = sound.id;
    return source;
  }

  createWaveFileData(audioBuffer, asFloat) {
    let bytesPerSample = asFloat ? 4 : 2;
    let frameLength = audioBuffer.length;
    let numberOfChannels = audioBuffer.numberOfChannels;
    let sampleRate = audioBuffer.sampleRate;
    let bitsPerSample = 8 * bytesPerSample;
    let byteRate = (sampleRate * numberOfChannels * bitsPerSample) / 8;
    let blockAlign = (numberOfChannels * bitsPerSample) / 8;
    let wavDataByteLength = frameLength * numberOfChannels * bytesPerSample;
    let headerByteLength = 44;
    let totalLength = headerByteLength + wavDataByteLength;
    let waveFileData = new Uint8Array(totalLength);
    let subChunk1Size = 16;
    let subChunk2Size = wavDataByteLength;
    let chunkSize = 4 + (8 + subChunk1Size) + (8 + subChunk2Size);

    writeString("RIFF", waveFileData, 0);
    writeInt32(chunkSize, waveFileData, 4);
    writeString("WAVE", waveFileData, 8);
    writeString("fmt ", waveFileData, 12);
    writeInt32(subChunk1Size, waveFileData, 16);
    writeInt16(asFloat ? 3 : 1, waveFileData, 20); // AudioFormat (2)
    writeInt16(numberOfChannels, waveFileData, 22); // NumChannels (2)
    writeInt32(sampleRate, waveFileData, 24); // SampleRate (4)
    writeInt32(byteRate, waveFileData, 28); // ByteRate (4)
    writeInt16(blockAlign, waveFileData, 32); // BlockAlign (2)
    writeInt32(bitsPerSample, waveFileData, 34); // BitsPerSample (4)
    writeString("data", waveFileData, 36);
    writeInt32(subChunk2Size, waveFileData, 40); // SubChunk2Size (4)
    writeAudioBuffer(audioBuffer, waveFileData, 44, asFloat);
    return waveFileData;
  }

  seek(time) {
    //this.reset(time);
    this.audioElement.currentTime = time;
    this.audioElement.innerText = time;
  }

  download() {
    if (this.blob) {
      invokeSaveAsDialog(this.blob);
    } else {
      console.log("no blob");
    }
  }

  load(data) {
    this.duration = 0;
    if (this.context) {
      this.context.close();
      this.context = null;
    }

    this.data = data;
    this.soundBank = [];
    this.nbBlock = 0;
    for (const layer of data.layers) {
      this.nbBlock += layer.sounds?.length || 0;
    }
    this.context = new window.AudioContext();
    for (const layer of data.layers) {
      for (const sound of layer.sounds) {
        const { volume, audio, id } = layer;
        this.loadSound(sound, { id, volume, audio });
      }
    }
  }

  loadSound(sound, config) {
    const oReq = new XMLHttpRequest();
    oReq.open("GET", "./wav/" + sound.url, true);
    oReq.responseType = "arraybuffer"; // eslint-disable-next-line
    oReq.onload = this.onLoad.bind(this, sound, config);
    oReq.send(null);
  }

  onLoad(block, config, res) {
    const audioData = res.currentTarget.response;
    this.context.decodeAudioData(
      audioData,
      this.onDecode.bind(this, block, config)
    );
  }

  onDecode(sound, config, buffer) {
    this.nbBlock--;
    const source = {};
    source.volume = config.volume;
    source.buffer = buffer;
    source.defaultStart = sound.start;
    source.defaultDuration = buffer.duration;
    source.id = sound.id;
    source.config = config;

    this.soundBank.push(source);

    const endTrack = source.defaultStart + source.defaultDuration;
    if (endTrack > this.duration) {
      this.duration = endTrack;
    }

    const eventSoundLoaded = new Event("sound-loaded");
    eventSoundLoaded.sound = source;
    document.dispatchEvent(eventSoundLoaded);

    if (this.nbBlock <= 0) {
      const eventLoaded = new Event("loaded");
      document.dispatchEvent(eventLoaded);
      this.update();
    }
  }
}
