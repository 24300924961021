import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../config";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getCurrent() {
    return axios.get(API_URL + "/users/current", { headers: authHeader() });
  }
  getPostcasts() {
    return axios.get(API_URL + "/users/current/podcasts", { headers: authHeader() });
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
}

export default new UserService();
