import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip,
  Typography,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  bulle: {
    borderRadius: 5,
    padding: 5,
    fontSize:14,
    display: "inline-block",
    background: "#EEE",
  },
  bulleAuthor: {
    borderRadius: 5,
    padding: 5,
    fontSize:14,
    display: "inline-block",
    color: "white",
    background: theme.palette.primary.light,
  },
  time: {
    color: "#DDD",
    fontSize: 12,
    display: "block",
  },
}));

function getLeft(props) {
  const classes = useStyles();
  return (
    <ListItem>
      <Tooltip arrow title={props.name}>
        <ListItemAvatar style={{ marginRight: 0 }}>
          <Avatar alt={props.name} src={props.image} />
        </ListItemAvatar>
      </Tooltip>
      <ListItemText
        align="left"
        primary={
          <React.Fragment>
            <Typography
              component="p"
              className={classes.bulle}
              color="textPrimary"
            >
              {props.message}
            </Typography>
            <span className={classes.time}>{props.time}</span>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}
function getRight(props) {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemText
        align="right"
        primary={
          <React.Fragment>
            <Typography
              component="p"
              className={classes.bulleAuthor}
              color="textPrimary"
              align="left"
            >
              {props.message}
            </Typography>
            <span className={classes.time}>{props.time}</span>
          </React.Fragment>
        }
      />

      <Tooltip arrow title={props.name}>
        <ListItemAvatar style={{ marginRight: 0 }}>
          <Avatar
            alt={props.name}
            src={props.image}
            style={{ marginLeft: 10 }}
          />
        </ListItemAvatar>
      </Tooltip>
    </ListItem>
  );
}

const Line = (props) => {
  return (
    <React.Fragment>
      {props.author ? getRight(props) : getLeft(props)}
    </React.Fragment>
  );
};
export default Line;
