import React from "react";
import { green } from "@material-ui/core/colors";
import { hextoRGB, gridContext } from "../../../../utils/tools";

export default function Oscilloscope(props) {
  const canvas = React.useRef();
  const requestRef = React.useRef();
  const analyser = React.useRef();

  function oscillo(ctx, analyser, clear, theme) {
    const oscillocolor = theme[400];
    const color = theme[800];
    const lineColor = theme[900];

    const cW = ctx.canvas.width;
    const cH = ctx.canvas.height;
    if (clear) {
      ctx.clearRect(0, 0, cW, cH);
    }
    if (color) {
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, cW, cH);
    }
    const fb = analyser.frequencyBinCount;
    const freqByteData = new Uint8Array(fb);
    analyser.getByteTimeDomainData(freqByteData);

    gridContext(ctx, cW, cH, lineColor);

    ctx.fillStyle = oscillocolor;

    for (let i = 0; i < fb; i++) {
      const value = freqByteData[i];
      const percent = value / 256;
      const height = cH * percent;
      const offset = cH - height - 1;
      const barWidth = cW / analyser.frequencyBinCount;
      ctx.fillRect(i * barWidth, offset, 2, 2);
    }
  }

  React.useEffect(() => {
    const animate = (time) => {
      if (canvas && canvas.current) {
        const context = canvas.current.getContext("2d");
        if (analyser.current) {
          oscillo(
            context,
            analyser.current,
            true,
            props.color ? props.color : green
          );
        }
      }
      // The 'state' will always be the initial value here
      requestRef.current = requestAnimationFrame(animate);
    };
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once

  React.useEffect(() => {
    analyser.current = props.analyser;
  }, [props.analyser]);

  if (analyser.current) {
    return (
      <canvas
        width={props.width}
        height={props.height}
        style={{ width: "100%", height: "100%" }}
        ref={canvas}
        style={{
          borderRadius: 20,
        }}
      />
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: props.color ? props.color[800] : green[800],
          width: props.width,
          height: props.height,
          display: "block",
          borderRadius: 20,
          width: "100%",
          height: "100%",
        }}
      />
    );
  }
}
