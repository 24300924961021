import {
  HashRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { pink, green, deepOrange, blue, amber } from "@material-ui/core/colors";
import IsMobileDialog from "./Components/DialogInfo/IsMobileDialog";
import NoInternetDialog from "./Components/DialogInfo/NoInternetDialog";
import MainMenu from "./Components/MainMenu";
import Footer from "./Components/Footer";
import Home from "./views/Home";
import About from "./views/About";
import Cgu from "./views/Cgu";
import Prices from "./views/Prices";
import Editor from "./views/Podcasts/Editor";
import Recorder from "./views/Podcasts/Recorder";
import Live from "./views/Podcasts/Live";
import MyPodcasts from "./views/User/MyPodcasts";
import MyAccount from "./views/User/MyAccount";
import Login from "./views/User/Login";
import SignUp from "./views/User/SignUp";
import PasswordRecovery from "./views/User/PasswordRecovery";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: blue[500],
      contrastText: "#fff",
    },
    secondary: {
      main: green[500],
      contrastText: "#fff",
    },
  },
});

export default function App() {
  const [open, setOpen] = useState(false);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MainMenu
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          theme={theme}
        />
        <Switch>
          <Route path="/" name="home" exact component={Home} />
          <Route path="/cgu" name="cgu" exact component={Cgu} />
          <Route path="/about" name="about" exact component={About} />
          <Route path="/prices" name="prices" exact component={Prices} />

          <Route path="/login" name="login" exact component={Login} />
          <Route path="/sign-up" name="signup" exact component={SignUp} />
          <Route
            path="/password-recovery"
            name="passwordrecovery"
            exact
            component={PasswordRecovery}
          />

          <Route
            path="/my-podcasts"
            name="my-podcasts"
            exact
            component={MyPodcasts}
          />

          <Route
            path="/my-account"
            name="myaccount"
            exact
            component={MyAccount}
          />

          <Route path="/editor" name="editor" exact component={Editor} />
          <Route
            path="/editor/:id"
            name="editor_podcast"
            exact
            component={Editor}
          />
          <Route path="/live/:id" name="live_podcast" exact component={Live} />

          <Route path="/recorder" name="recorder" exact component={Recorder} />
          <Route
            path="/recorder/:id"
            name="record_podcast"
            exact
            component={Recorder}
          />
        </Switch>
        <Footer />
      </Router>

      <IsMobileDialog />
      <NoInternetDialog />
    </ThemeProvider>
  );
}
