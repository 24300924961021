import React, { useState, useEffect } from "react";
import {
  Link,
  Backdrop,
  Typography,
  Breadcrumbs,
  CircularProgress,
  TextField,
  Container,
} from "@material-ui/core";
import { useHistory, Link as LinkRouter, useParams } from "react-router-dom";
import Timeline from "./Timeline";
import myPodcast from "../../../podcast-text";
import myPodcast2 from "../../../podcast-text2";
import PodcastService from "../../../services/podcast.service";
import AuthService from "../../../services/auth.service";
import useTitle from "../../../Hooks/useTitle";
import useScrollTop from "../../../Hooks/useScrollTop";

export default function Component(props) {
  const [podcast, setPodcast] = useState(myPodcast); //{ title:"",duration: 0, layers: [] });
  const [currentTime, setCurrentTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const titlePage = useTitle();
  const [user, setUser] = useState(AuthService.getCurrentUser());
  let { id } = useParams();

  useScrollTop();

  function updateTitle(newTitle) {
    setTitle(newTitle);
    titlePage.setTitle(newTitle);
  }

  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
    titlePage.setTitle("Please wait loading...");
  }, []);

  useEffect(() => {
    setLoading(true);
    PodcastService.getOne(id)
      .then((response) => {
        //setPodcast(response.data); // Load and replace current podcast
        updateTitle(response.data.title);
      })
      .then(() => {
        setLoading(false);
      });
  }, [id]);

  function load1() {
    setPodcast(myPodcast);
  }

  function load2() {
    setPodcast(myPodcast2);
  }

  function onChangeTitle(e) {
    setTitle(e.target.value);
  }

  return (
    <Container style={{ marginTop: 10 }} disableGutters>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          onChange={onChangeTitle}
          value={title}
        />
        {/* <br />
        <button onClick={load1}>load 1</button>
        <button onClick={load2}>load 2</button> */}
      </div>

      <Timeline data={podcast} />
    </Container>
  );
}
