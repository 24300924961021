import { useState } from "react";
import { Divider, Grid, Button, Link, Typography } from "@material-ui/core";
import { Link as LinkHistory } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { common } from "@material-ui/core/colors";
import AuthService from "../services/auth.service";

const links = [
  {
    name: "Information",
    links: [
      { name: "About", url: "/about" },
      { name: "CGU", url: "/cgu" },
      { name: "Prices", url: "/prices" },
    ],
  },
  {
    name: "Resources",
    links: [
      { name: "SoundBanks", url: "/resources/soudbanks" },
      { name: "How to start", url: "/resources/howto" },
      { name: "Testimonials", url: "/resources/testimonials" },
    ],
  },
  {
    name: "Blog",
    links: [
      { name: "What ?", url: "/resources/soudbanks" },
      { name: "Where ?", url: "/resources/howto" },
      { name: "How ?", url: "/resources/testimonials" },
    ],
  },
];

function getFooterMenu(data, b) {
  return (
    <Grid item xs={2} key={b}>
      <p>
        <strong>{data.name}</strong>
      </p>
      {data.links.map(function (link, i) {
        return (
          <p key={i}>
            <Link
              style={{ color: "#AAA" }}
              to={link.url}
              component={LinkHistory}
            >
              {link.name}
            </Link>
          </p>
        );
      })}
    </Grid>
  );
}

export default function Footer() {
  const [user, setUser] = useState(AuthService.getCurrentUser());
  return (
    <footer>
      <Grid container>
        <Grid item xs={2}>
          <strong style={{ fontSize: "2em" }} className="titleShadow">
            The Podcaster
          </strong>
          <p>
            We care about your voice in <strong>the world.</strong>
          </p>
        </Grid>
        <Grid item xs={1}>
          <Divider
            orientation="vertical"
            light
            style={{
              color: "white",
              background: "white",
              marginLeft: 55,
            }}
          />
        </Grid>
        {links.map(function (data, b) {
          return getFooterMenu(data, b);
        })}
        <Grid item xs={1}>
          <Divider
            orientation="vertical"
            light
            style={{
              color: "white",
              background: "white",
              marginLeft: 55,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <p>
            <strong>Follow us</strong>
          </p>
          <Button startIcon={<FacebookIcon />} style={{ color: "white" }}>
            Facebook
          </Button>
          <br />
          <Button startIcon={<TwitterIcon />} style={{ color: "white" }}>
            Twitter
          </Button>
        </Grid>

        <Grid container style={{ marginTop: 30 }}>
          <Grid item xs={6}>
            <Typography variant="body1" color="inherit">
              © {new Date().getFullYear()} The Podcaster
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {!user ? (
              <div>
                <Link
                  style={{ color: "#FFF" }}
                  component={LinkHistory}
                  to="/login"
                >
                  Connexion
                </Link>
                {" - "}
                <Link
                  style={{ color: "#FFF" }}
                  component={LinkHistory}
                  to="/sign-up"
                >
                  Sign Up
                </Link>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
