import React, { useRef } from "react";
import { Grid, TextField, Fab, Link } from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";

const Title = (props) => {
  const titleForm = useRef(null);

  return (
    <Grid container style={{ padding: "10px" }} spacing={4}>
      <Grid item xs={11}>
        <TextField
          defaultValue={props?.podcast?.title}
          label="Podcast title"
          fullWidth
          variant="filled"
          ref={titleForm}
        />
      </Grid>
      <Grid item xs={1}>
        <Fab color="primary" aria-label="add" size="small">
          <SendIcon fontSize="small" />
        </Fab>
      </Grid>
    </Grid>
  );
};

export default Title;
