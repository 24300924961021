import React, { useState, useEffect } from "react";
import { Link as LinkHistory, useHistory } from "react-router-dom";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Backdrop,
  Link,
  CircularProgress,
  Paper,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import { isEmail } from "validator";
import useTitle from "../../../Hooks/useTitle";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Podcaster
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/assets/bg/bg1.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmRegistration, setConfirmRegistration] = useState(false);
  let history = useHistory();
  const title = useTitle();

  useEffect(() => {
    title.setTitle("Sign Up");
  }, []);

  function onChangeFirstname(e) {
    e.preventDefault();
    setFirstname(e.target.value);
  }

  function onChangeLastname(e) {
    e.preventDefault();
    setLastname(e.target.value);
  }

  function onChangeEmail(e) {
    e.preventDefault();
    setEmail(e.target.value);
    setEmailError(!isEmail(e.target.value));
  }

  function onChangePassword(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }

  function onChangeConfirmPassword(e) {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  }

  function handleSignUp(e) {
    e.preventDefault();
    setEmailError(false);
    setFirstnameError(false);
    setLastnameError(false);
    setConfirmPasswordError(false);
    setPasswordError(false);
    setErrorMessage(false);

    if (
      firstname &&
      lastname &&
      isEmail(email) &&
      password &&
      confirmPassword
    ) {
      setLoading(true);
      AuthService.register(firstname, lastname, email, password)
        .then((response) => {
          // user registred
          setLoading(false);
          setConfirmRegistration(true);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            setErrorMessage(error.response.data.message);
          }
        });
    } else {
      setEmailError(!email || email !== "" || (email && !isEmail(email)));
      setFirstnameError(!firstname || firstname === "");
      setLastnameError(!lastname || lastname === "");
      setPasswordError(!firstname || firstname === "");
      setPasswordError(!password || password === "");
      setConfirmPasswordError(!confirmPassword || password !== confirmPassword);
    }
  }

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {confirmRegistration ? (
            <div>Thanks you for registred</div>
          ) : (
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>

              {errorMessage ? <div>{errorMessage}</div> : ""}

              <form className={classes.form} noValidate onSubmit={handleSignUp}>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Firstname"
                      autoFocus
                      error={firstnameError}
                      onChange={onChangeFirstname}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Lastname"
                      error={lastnameError}
                      onChange={onChangeLastname}
                    />
                  </Grid>
                </Grid>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Email Address"
                  error={emailError}
                  onChange={onChangeEmail}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  error={passwordError}
                  onChange={onChangePassword}
                  autoComplete="current-password"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Confirm password"
                  type="password"
                  error={confirmPasswordError}
                  onChange={onChangeConfirmPassword}
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </Button>

                <Grid container>
                  <Grid item xs>
                    <Link to="/login" variant="body2" component={LinkHistory}>
                      {"Login"}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/password-recovery"
                      variant="body2"
                      component={LinkHistory}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
