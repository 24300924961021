import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";

import "../../../css/style.scss";

export default class LayerLabel extends Component {
  btnMute;
  btnSolo;

  constructor(props) {
    super(props);
    this.state = {
      isMute: this.props.config.isMute ?? false,
      isSolo: this.props.config.isSolo ?? false,
      volume: this.props.config.volume ?? 100,
      name: this.props.config.name ?? this.props.name,
      config: this.props.config,
    };
  }

  componentDidMount() {
    const updateConfig = this.updateConfig.bind(this);

    this.btnMute.addEventListener("click", function (e) {
      e.target.classList.toggle("mute");
      updateConfig("isMute", e.target.classList.contains("mute"));
    });

    this.btnSolo.addEventListener("click", function (e) {
      e.target.classList.toggle("solo");
      updateConfig("isSolo", e.target.classList.contains("solo"));
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      config: nextProps.config,
      isSolo: nextProps.config.isSolo,
      isMute: nextProps.config.isMute,
      volume: nextProps.config.volume,
      name: nextProps.config.name,
    });
  }

  handleChange(e, newValue) {
    this.setState({ volume: newValue });
    this.updateConfig("volume", newValue);
  }

  updateConfig(name, value) {
    const { config } = this.state;
    config[name] = value;
    this.props.callbackConfig(this.props.config.id, name, config);
  }

  handleNameChange(e) {
    this.updateConfig("name", e.target.value);
  }

  render() {
    const colorDisplayHandle = this.props.colorDisplayHandle;
    const { config, isMute, isSolo } = this.state;
    return (
      <div className="layer">
        <div className="label" title={this.state.name}>
          <Grid container spacing={2}>
            <Grid  item xs={10}>
              <input
                type="text"
                defaultValue={config.name}
                onChange={this.handleNameChange.bind(this)}
              />
            </Grid>
            <Grid  item xs={2}>
              <div
                style={{
                  float: "right",
                  background: config.color,
                  height: 15,
                  width: 15,
                  cursor: "pointer",
                }}
                onClick={function (e) {
                  colorDisplayHandle(e, config.id);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs>
            <Slider
              style={{ color: "grey" }}
              value={this.state.volume}
              onChange={this.handleChange.bind(this)}
              aria-labelledby="continuous-slider"
            />
          </Grid>
          <Grid item>
            <div className="buttons">
              <button
                ref={(c) => (this.btnMute = c)}
                className={isMute ? "mute" : ""}
              >
                Mute
              </button>
              <button
                ref={(c) => (this.btnSolo = c)}
                className={isSolo ? "solo" : ""}
              >
                Solo
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
