import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Paper,
  Card,
  CardMedia,
} from "@material-ui/core";
import { useHistory, Link as LinkRouter } from "react-router-dom";
import { MicOff, Mic } from "@material-ui/icons";
import { yellow, blue } from "@material-ui/core/colors";
import green from "@material-ui/core/colors/green";
import {
  Link,
  Breadcrumbs,
  CircularProgress,
  Typography,
  Backdrop,
  Divider,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import Recording from "../Recorder/Audio/Recording";
import SpectrumAnalyzer from "../Recorder/Audio/SpectrumAnalyzer";
import Oscilloscope from "../Recorder/Audio/Oscilloscope";
import PodcastService from "../../../services/podcast.service";
import AuthService from "../../../services/auth.service";
import Chat from "../../../Components/Chat";
import CardPlayer from "./Card";
import useTitle from "../../../Hooks/useTitle";

const sizeAudioVisualizer = 320;

export default function Component(props) {
  const recorder = new Recording();
  let history = useHistory();
  let { id } = useParams();
  const colorPanel = useRef(null);
  const [user, setUser] = useState(AuthService.getCurrentUser());
  const [analyser, setAnalyser] = useState();
  const [loading, setLoading] = useState(false);
  const [podcast, setPodcast] = useState();
  const [playing, setPlaying] = useState(false);
  const title = useTitle();

  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
    setLoading(true);
    PodcastService.getOne(id).then((response) => {
      setPodcast(response.data);
      setLoading(false);
      title.setTitle("Live - "+response.data.title);
    });
    document.addEventListener("connect", connect);
    document.addEventListener("disconnect", disconnect);
    document.addEventListener("start", start);
    document.addEventListener("stop", stop);
    recorder.setRoom(id);
    recorder.listen();
    return () => {
      document.removeEventListener("connect", connect);
      document.removeEventListener("disconnect", disconnect);
      document.removeEventListener("start", start);
      document.removeEventListener("stop", stop);
    };
  }, []);

  function connect() {
    console.log("ready to listen");
  }
  function disconnect() {
    setRecordBtnDisable(true);
  }
  function start() {
    setAnalyser(recorder.getAnalyser());
  }
  function stop() {
    setIsRecording(false);
  }

  function handlePlayBtn() {
    setPlaying(!playing);
  }

  return (
    <div style={{ paddingTop: 15 }}>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container>
        <Grid item xs={9}>
          <Grid
            container
            align="center"
            spacing={0}
            style={{ paddingTop: "10px" }}
          >
            <Grid item xs={4}>
              <CardPlayer
                podcast={podcast}
                playing={playing}
                handleClick={handlePlayBtn}
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                borderRadius: 20,
                background: "url(https://picsum.photos/1024/768)",
                backgroundSize: "cover",
              }}
            />

            <Grid item xs={4}>
              <Oscilloscope
                width={sizeAudioVisualizer}
                height={sizeAudioVisualizer}
                analyser={analyser}
                color={blue}
              />
            </Grid>
          </Grid>

          <div id="video-grid">
            <video playsInline autoPlay muted id="local-video"></video>
            <video playsInline autoPlay id="remote-video"></video>
          </div>

          <div>
            <span>Message: </span>
            <input type="text" id="message-input" title="Message to Send!" />
            <button id="message-button">Send</button>
          </div>

          <div className="messages"></div>
        </Grid>
        <Grid item xs={3}>
          <Chat />
        </Grid>
      </Grid>
    </div>
  );
}
