import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory, Link as LinkRouter } from "react-router-dom";
import { MicOff, Mic } from "@material-ui/icons";
import { yellow, blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import MicOffIcon from "@material-ui/icons/MicOff";
import EditIcon from "@material-ui/icons/Edit";
import VolumeUp from "@material-ui/icons/VolumeUp";
import { Alert } from "@material-ui/lab";
import RadioIcon from "@material-ui/icons/Radio";
import MusicVideoIcon from "@material-ui/icons/MusicVideo";
import {
  CircularProgress,
  Backdrop,
  Grid,
  Button,
  Paper,
  Drawer,
  Link,
  ButtonGroup,
  Tooltip,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import Recording from "./Audio/Recording";
import Oscilloscope from "./Audio/Oscilloscope";
import PodcastService from "../../../services/podcast.service";
import AuthService from "../../../services/auth.service";
import Chat from "../../../Components/Chat";
import TitleForm from "./Form/title";
import ImageForm from "./Form/image";
import InviteSpeakers from "../../../Components/Speakers/invite";
import useTitle from "../../../Hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const sizeAudioVisualizer = 256;

export default function Component(props) {
  const classes = useStyles();
  let recorder = new Recording();
  let history = useHistory();
  let { id } = useParams();
  const [user, setUser] = useState(AuthService.getCurrentUser());
  const [analyser, setAnalyser] = useState();
  const [loading, setLoading] = useState(false);
  const [recordBtnDisable, setRecordBtnDisable] = useState();
  const [podcast, setPodcast] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const title = useTitle();

  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
    setLoading(true);
    PodcastService.getOne(id).then((response) => {
      setPodcast(response.data);
      setLoading(false);
      title.setTitle("Recording - "+response.data.title);
      //startListen();
    });
    recorder.setRoom(id);
    document.addEventListener("connect", connect);
    document.addEventListener("disconnect", disconnect);
    document.addEventListener("start", start);
    document.addEventListener("stop", stop);
    return () => {
      document.removeEventListener("connect", connect);
      document.removeEventListener("disconnect", disconnect);
      document.removeEventListener("start", start);
      document.removeEventListener("stop", stop);
    };
  }, []);

  function connect() {
    setRecordBtnDisable(false);
    console.log("socket to record is ready !");
  }
  function disconnect() {
    setRecordBtnDisable(true);
  }
  function start() {
    setIsRecording(true);
    setAnalyser(recorder.getAnalyser());
  }
  function stop() {
    setIsRecording(false);
  }

  function startRecord() {
    if (recorder) {
      if (isRecording) {
        recorder.stop();
      } else {
        recorder.start();
      }
      setIsRecording(!isRecording);
    }
  }

  function getLinkLive() {
    return "http://" + document.location.host + "/#/live/" + id;
  }
  function getAlert(props) {
    if (props.status === "onair") {
      return (
        <Alert style={{ background: "#EEE" }} icon={<RadioIcon />}>
          You are now On Air, share link for live :{" "}
          <Link href={getLinkLive()} target="_blank">
            {getLinkLive()}
          </Link>
        </Alert>
      );
    } else {
      return (
        <Alert
          color="inherit"
          style={{ background: "#EEE" }}
          icon={<MusicVideoIcon />}
        >
          You actually not On Air. Nobody except you and your speakers can
          listen and record.
        </Alert>
      );
    }
  }

  return (
    <div>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Grid
            container
            align="center"
            spacing={2}
            style={{ padding: "10px" }}
          >
            <Grid item xs={12}>
              {podcast ? getAlert(podcast) : ""}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">
                {podcast?.title}
              </Typography>
            </Grid>

            {/*<audio controls id="test"></audio>*/}

            <Grid item xs={12}>
              <ButtonGroup>
                <Button
                  variant="contained"
                  disabled={recordBtnDisable}
                  startIcon={isRecording ? <MicOff /> : <Mic />}
                  style={isRecording ? { backgroundColor: yellow[700] } : {}}
                  color={!isRecording ? "secondary" : "primary"}
                  size={"small"}
                  onClick={startRecord}
                >
                  {isRecording ? "Stop recording" : "Start recoding"}
                </Button>
                <Button
                  variant="contained"
                  size={"small"}
                  startIcon={<MicOffIcon />}
                >
                  Mute
                </Button>
                <Button
                  variant="contained"
                  size={"small"}
                  style={{ background: yellow[900], color: "white" }}
                  startIcon={<EditIcon />}
                  onClick={() => setEditOpen(true)}
                >
                  Edit
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Oscilloscope
                width={sizeAudioVisualizer}
                height={sizeAudioVisualizer}
                analyser={analyser}
                color={blue}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {Array.apply(0, Array(24)).map(function (x, i) {
                  return (
                    <Grid item xs={1} key={i}>
                      <Tooltip arrow title={"Sound " + (i + 1)}>
                        <Paper
                          component={Button}
                          className={classes.paper}
                          style={{ margin: 2, height: 60 }}
                        >
                          <VolumeUp size="small" />
                        </Paper>
                      </Tooltip>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <div id="video-grid">
              <video playsInline autoPlay muted id="local-video"></video>
              <video playsInline autoPlay id="remote-video"></video>
            </div>

            <div>
              <span>Message: </span>
              <input type="text" id="message-input" title="Message to Send!" />
              <button id="message-button">Send</button>
            </div>

            <div className="messages"></div>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Chat />
        </Grid>
      </Grid>

      <Drawer
        open={editOpen}
        anchor="bottom"
        onClose={() => setEditOpen(false)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} align="center" style={{ marginTop: 10 }}>
            <FormControlLabel
              control={<Switch color="primary" size="medium" />}
              label="On Air"
            />
            <br />
            <FormControlLabel
              control={<Switch color="primary" size="medium" />}
              label="Chat"
            />
          </Grid>
          <Grid item xs={2}>
            <ImageForm podcast={podcast} />
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12}>
                <TitleForm podcast={podcast} />
              </Grid>
              <Grid item xs={12}>
                <InviteSpeakers />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}
