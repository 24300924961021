import React, { Component } from "react";

import Block from "./Block";
import "css/style.scss";

export default class Layer extends Component {
  constructor(props) {
    super(props);
    this.displayBlock = this.displayBlock.bind(this);
  }

  displayBlock(layer, index) {
    const sounds = layer.sounds;
    const classLine = index % 2 === 0 ? "line line-even" : "line line-odd";
    const { callback, isPlaying, editor } = this.props;

    return (
      <div
        key={index}
        style={{ width: editor.duration * editor.scale }}
        className={classLine}
      >
        {sounds.map(function (sound, index) {
          return (
            <Block
              key={index}
              block={sound}
              scale={editor.scale}
              callback={callback}
              layer={layer}
              isPlaying={isPlaying}
            />
          );
        })}
      </div>
    );
  }

  render() {
    return <div>{this.props.layers.map(this.displayBlock)}</div>;
  }
}
