import React, { Component } from "react";
import "../../../css/style.scss";
import { Grid, ButtonGroup, Button, Fab } from "@material-ui/core";

import {
  ZoomIn,
  ZoomOut,
  CloudDownload,
  PlayArrow,
  Pause,
  Stop,
  GetApp,
  PanTool,
  Edit,
} from "@material-ui/icons";
import green from "@material-ui/core/colors/green";

export default class MenuTop extends Component {
  render() {
    return (
      <Grid container style={{ padding: 20 }}>
        <Grid item xs={4}>
          <ButtonGroup size="large">
            <Button
              style={
                this.props.isPlaying
                  ? { backgroundColor: green[700], color: "white" }
                  : {}
              }
              variant="contained"
              startIcon={<PlayArrow />}
              onClick={this.props.controlHandle("play")}
            />
            <Button
              disabled={!this.props.isPlaying}
              variant="contained"
              startIcon={<Pause />}
              onClick={this.props.controlHandle("pause")}
            />
            <Button
              variant="contained"
              disabled={!this.props.currentTime}
              startIcon={<Stop />}
              onClick={this.props.controlHandle("stop")}
            />
          </ButtonGroup>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center", display: "block" }}>
          <Fab
            aria-label="zoom In"
            onClick={this.props.controlHandle("zoomin")}
            size={"small"}
          >
            <ZoomIn />
          </Fab>
          <Fab
            aria-label="zoom Out"
            onClick={this.props.controlHandle("zoomout")}
            style={{ marginLeft: 10 }}
            size={"small"}
          >
            <ZoomOut />
          </Fab>
          <Fab style={{ marginLeft: 10 }} size={"small"}>
            <PanTool />
          </Fab>
          <Fab style={{ marginLeft: 10 }} size={"small"}>
            <Edit />
          </Fab>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right", display: "block" }}>
          {this.props.replay ? (
            <Button
              variant="contained"
              startIcon={<GetApp />}
              onClick={this.props.controlHandle("download")}
            >
              Download
            </Button>
          ) : (
            ""
          )}
          <Button 
            variant="contained"
            startIcon={<CloudDownload />}
            onClick={this.props.controlHandle("save")}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
}
