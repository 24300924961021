import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import Line from "./Line";
import speakers from "../../mockup/speakers";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  inviteSection: {
    width: "100%",
    height: "70vh",
  },
  inviteArea: {
    height: "60vh",
    marginTop: -10,
    overflowY: "auto",
  },
}));

const Invite = () => {
  const classes = useStyles();

  return (
    <Grid container  className={classes.inviteSection}>
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={11}>
          <TextField
            id="outlined-basic-email"
            label="Search a user"
            variant="filled"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={1} align="center">
          <Fab color="primary" aria-label="add" size="small">
            <SearchIcon fontSize="small" />
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <List className={classes.inviteArea}>
          {speakers.map(function (message, i) {
            return <Line key={i} {...message} />;
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default Invite;
