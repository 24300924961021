import React from 'react'
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';

export default function NoInternetDialog(props) {

  const [noInternet, setNoInternet] = React.useState(true);

  const checkOnline = function () {
    setNoInternet(!navigator.onLine);
  };

  React.useEffect(() => {
    window.addEventListener("offline", checkOnline);
    window.addEventListener("online", checkOnline);
    checkOnline();

    return () => {
      window.removeEventListener("offline", checkOnline);
      window.removeEventListener("online", checkOnline);
    };
  }, []); // Make sure the effect runs only once
  return (
    <Dialog
      open={noInternet}
    >
      <DialogTitle style={{textAlign: 'center'}}>{"Connection to internet lost"}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{textAlign: 'center'}}>
          <div style={{textAlign: 'center'}}>
            {noInternet ?
              <WifiOffIcon style={{fontSize: 160, color: "#DDD"}}/>
              :
              <WifiIcon style={{fontSize: 160, color: "#555"}}/>
            }

            <p>Please check your connection to continue<br/>
              or all your work until that will be list.</p>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
