import React from 'react'
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MobileOffIcon from '@material-ui/icons/MobileOff';
import {isMobile} from "../../utils/tools";

export default function IsMobileDialog(props) {
  const [mobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    //setMobile(isMobile());
  }, []); // Make sure the effect runs only once

  return (
    <Dialog
      open={mobile}
    >
      <DialogTitle tyle={{textAlign: 'center'}}>{"Information"}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{textAlign: 'center'}}>
          <div style={{textAlign: 'center'}}>
            <MobileOffIcon style={{fontSize: 160, color: "#DDD"}}/>
            <p>The app is not available for mobile yet.</p>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
