import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Backdrop,
  Link,
  Breadcrumbs,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import CardPodcast from "../../../Components/CardPodcast";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import NewBtn from "./New";
import useTitle from "../../../Hooks/useTitle";

export default function Components(props) {
  let history = useHistory();
  const [user, setUser] = useState(AuthService.getCurrentUser());
  const [modalNewOpen, setModalNewOpen] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { ...rest } = props;
  const title = useTitle();

  useEffect(() => {
    title.setTitle("My Podcasts");
    if (!user) {
      history.push("/login");
    }
    setLoading(true);
    UserService.getPostcasts().then((response) => {
      setPodcasts(response.data['hydra:member']);
      setLoading(false);
    });
  }, []);

  return (
    <Container style={{ marginTop: 10 }} disableGutters>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <br />
      <NewBtn />
      <br />
      <br />
      <Grid container spacing={2}>
        {podcasts.map(function (podcast, i) {
          return (
            <Grid key={i} item xs={8} sm={4} md={3}>
              <CardPodcast podcast={podcast} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
