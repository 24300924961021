import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";

import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getLink = (podcast) => {
    return podcast.status == "editable"
      ? "/editor/" + podcast.id
      : "/recorder/" + podcast.id;
  };

  const getChip = (podcast) => {
    if (podcast.status === "editable") {
      return <Chip label="editable" color="secondary" size="small" />;
    } else if (podcast.status === "onair") {
      return <Chip label="onair" color="primary" size="small" />;
    } else {
      return <Chip label="ready" size="small" />;
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={props.podcast.title}
        subheader={props?.podcast.date}
      />
      <CardMedia
        className={classes.media}
        image={"https://picsum.photos/350/226"}
        title={props.podcast.title}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.podcast.description}
        </Typography>
        {getChip(props.podcast)}
      </CardContent>
      <CardActions disableSpacing>
        12 389 listens on Podcaster
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ShareIcon />
        </IconButton>
      </CardActions>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={getLink(props.podcast)}
        >
          <EditIcon fontSize="inherit" style={{ marginRight: 10 }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <DeleteIcon fontSize="inherit" style={{ marginRight: 10 }} /> Delete
        </MenuItem>
      </Menu>
    </Card>
  );
}
