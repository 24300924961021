import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import Line from "./Line";
import chatMessages from "../../mockup/chat";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "92vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "80vh",
    overflowY: "auto",
  },
});

const Chat = () => {
  const classes = useStyles();
  const messagesEndRef = useRef(null);

  function scrollToBottom(smooth) {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView(
        smooth ? { behavior: "smooth" } : {}
      );
    }
  }

  useEffect(() => {
    scrollToBottom(true);
  }, [chatMessages]);

  return (
    <Grid container component={Paper} className={classes.chatSection}>
      <Grid item xs={12}>
        <List className={classes.messageArea}>
          {chatMessages.map(function (message, i) {
            return <Line key={i} {...message} />;
          })}
          <div ref={messagesEndRef} />
        </List>
        <Divider />
      </Grid>
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={10}>
          <TextField
            label="Type Something"
            size="small"
            variant="filled"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} align="right">
          <Fab color="primary" aria-label="add" size="small">
            <SendIcon fontSize="small" />
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chat;
