import React, { Component } from "react";
import {
  Grid,
  CircularProgress,
  Backdrop,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { GithubPicker } from "react-color";
import Timer from "./Timer";
import Counter from "./Counter";
import Layer from "./Layer";
import LayerLabel from "./LayerLabel";
import Replay from "./Audio/Replay";
import MenuTop from "./MenuTop";
import "../../../css/style.scss";
import { pickerColors } from "../../../utils/tools";

export default class Timeline extends Component {
  cursor;
  blockLayer;
  colorPanel;
  colorId;
  analyser;
  replay;
  durationTimeout;

  constructor(props) {
    super(props);
    this.state = {
      currentTime: this.props.currentTime ?? 0,
      isPlaying: this.props.isPlaying ?? false,
      isLoading: true,
      data: this.props.data ?? {},
      duration: this.props.data.duration ?? 0,
      scrollerOffsetX: 0,
      startTime: false,
      follow: false,
      editor: {
        duration: 40,
        scale: 75,
      },
    };
    this.callback = this.props.scene_callback
      ? this.props.scene_callback
      : false;
    this.editcallback = this.props.editcallback
      ? this.props.editcallback
      : false;
    this.controlHandle = this.controlHandle.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress);
    this.updateCursor();
    this.colorPanel = document.getElementById("color-panel");

    this.replay = new Replay(this.state.data);
    this.replay.load(this.state.data);

    document.addEventListener(
      "loaded",
      function () {
        const { editor } = this.state;
        editor.duration = this.replay.getDuration();
        this.setState({ isLoading: false, editor });
      }.bind(this)
    );

    document.addEventListener(
      "sound-loaded",
      function (e) {
        const { data } = this.state;
        const layerIndex = data.layers.findIndex(
          (x) => x.id === e.sound.config.id
        );
        const layer = data.layers[layerIndex];
        const blockIndex = layer.sounds.findIndex((x) => x.id === e.sound.id);
        layer.sounds[blockIndex].duration = e.sound.defaultDuration.toFixed(3);
        layer.sounds[blockIndex].sound = e.sound;
        data.layers[layerIndex] = layer;
        this.setState({ data });
      }.bind(this)
    );

    this.blockLayer.addEventListener(
      "scroll",
      function (event) {
        this.setState({ scrollerOffsetX: event.target.scrollLeft });
      }.bind(this),
      false
    );

    this.updateCurrentTime();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ isLoading: true });
    this.replay.setData(nextProps.data);
    this.setState({ data: nextProps.data });
    this.updateCurrentTime();
  }

  componentWillUnmount() {
    document.removeEventListener("loaded", function () {});
    document.removeEventListener("sound-loaded", function () {});
    document.removeEventListener("click", function () {});
    document.removeEventListener("connect", function () {});
    document.removeEventListener("disconnect", function () {});
    document.removeEventListener("start", function () {});
    document.removeEventListener("stop", function () {});
    document.removeEventListener("keydown", function () {});
    this.blockLayer.removeEventListener("scroll", function () {});
  }

  updateCurrentTime() {
    if (this.state.isPlaying && this.state.startTime) {
      const dateNow = new Date().getTime();
      let currentTime = (dateNow - this.state.startTime) / 1000;
      this.setState({ currentTime });
      this.updateCursor();
    }
    requestAnimationFrame(this.updateCurrentTime.bind(this));
  }

  playAction() {
    let startTime = new Date().getTime();
    if (this.state.currentTime) {
      startTime -= this.state.currentTime * 1000;
    }
    this.replay.play();
    this.setState({ isPlaying: true, startTime });
  }

  stopAction() {
    this.replay.stop();
    this.setState({ currentTime: 0, isPlaying: false, startTime: null });
    setTimeout(this.updateCursor.bind(this), 100);
  }

  pauseAction() {
    this.replay.pause();
    this.setState({ startTime: false, isPlaying: false });
  }

  controlHandle = (action) => () => {
    switch (action) {
      case "play":
        this.playAction();
        break;
      case "stop":
        this.stopAction();
        break;
      case "pause":
        this.pauseAction();
        break;
      case "zoomin":
        this.zoomIn();
        break;
      case "zoomout":
        this.zoomOut();
        break;
      case "save":
        this.saveHandle();
        break;
      case "download":
        this.replay.download();
        break;
      default:
        break;
    }
  };

  zoomIn() {
    const { editor } = this.state;
    editor.scale += 20;
    this.setState({ editor });
    this.updateCursor();
    this.cursor.scrollIntoView({
      inline: "center",
    });
    this.updateTime(this.state.currentTime * editor.scale);
  }

  zoomOut() {
    const { editor } = this.state;
    const newScale = editor.scale - 20;
    if (newScale > 5) {
      editor.scale = newScale;
      this.setState({ editor });
      this.updateCursor();
      this.cursor.scrollIntoView({
        inline: "center",
      });
      this.updateTime(this.state.currentTime * newScale);
    }
  }

  updateTime(posX, updateReplay) {
    const { editor } = this.state;
    this.setState({ currentTime: posX / editor.scale });
    if (this.replay && updateReplay) {
      this.replay.seek(this.state.currentTime);
    }
    this.updateCursor();
  }

  updateCursor() {
    const { editor, currentTime } = this.state;
    if (!this.cursor) {
      return;
    }
    const duration = this.state.editor.duration * this.state.editor.scale;
    let posX = currentTime * editor.scale;
    if (posX > duration && this.state.isPlaying) {
      posX = duration;
      this.setState({ isPlaying: false });
    }
    this.cursor.style.left = posX + "px";
    if ((this.state.isPlaying && this.state.follow) || currentTime === 0) {
      this.cursor.scrollIntoView({
        inline: "center",
      });
    }
  }

  callbackMoveBlock(id, blockId, start) {
    const { data } = this.state;
    const layerIndex = data.layers.findIndex((x) => x.id === id);
    const layer = data.layers[layerIndex];
    const blockIndex = layer.sounds.findIndex((x) => x.id === blockId);
    layer.sounds[blockIndex].start = start;
    if (this.replay) {
      this.replay.updateBlockStart(blockId, start);
    }
    data.layers[layerIndex] = layer;
    this.setState({ data });
  }

  callbackConfigHandle(id, fieldUpdated, newConfig) {
    const { data } = this.state;

    if (fieldUpdated === "isMute") {
      newConfig.audio = !newConfig.isMute;
    }

    if (fieldUpdated === "isSolo") {
      if (newConfig.isSolo) {
        for (let b in data.layers) {
          if (id === data.layers[b].id) {
            newConfig.isSolo = true;
            newConfig.audio = true;
          } else {
            data.layers[b].isSolo = false;
            data.layers[b].audio = false;
          }
        }
      } else {
        for (let b in data.layers) {
          data.layers[b].audio = !data.layers[b].isMute;
        }
      }
    }
    this.replay.updateConfig(data.layers);

    const layerIndex = data.layers.findIndex((x) => x.id === id);
    data.layers[layerIndex] = newConfig;

    this.setState({ data });
  }

  saveHandle() {
    const { data } = this.state;
    const clean = ["waveform", "enabled", "timecode", "data", "sound"];
    for (const l in data.layers) {
      for (const b in data.layers[l].blocks) {
        for (const f of clean) {
          delete data.layers[l].blocks[b][f];
        }
      }
    }
    console.log(JSON.stringify(data));
  }

  colorDisplayHandle(e, id) {
    if (this.colorId === id && this.colorPanel.style.display === "") {
      this.colorPanel.style.display = "none";
    } else {
      this.colorId = id;
      this.colorPanel.style.left = e.pageX - 20 + "px";
      this.colorPanel.style.top = e.pageY + 20 + "px";
      this.colorPanel.style.display = "";
    }
  }

  onColorChange(v) {
    const { data } = this.state;
    this.colorPanel.style.display = "none";
    if (this.colorId) {
      const layerIndex = data.layers.findIndex((x) => x.id === this.colorId);
      data.layers[layerIndex].color = v.hex;
      this.callbackConfigHandle(this.colorId, "color", data.layers[layerIndex]);
    }
  }

  handleFollow(v) {
    this.setState({ follow: v.target.checked });
  }

  onChangeDuration(e) {
    if (this.durationTimeout) {
      clearTimeout(this.durationTimeout);
    }
    this.durationTimeout = setTimeout(
      function () {
        const { editor } = this.state;
        editor.duration = parseInt(e.target.value);
        this.setState({ editor });
        this.replay.setDuration(this.state.editor.duration);
      }.bind(this),
      800
    );
  }

  handleKeyPress = (event) => {
    /*
    if (event.code === 'Space') {
      if (!this.state.isRecording) {
        if (this.state.isPlaying) {
          this.pause();
        } else {
          this.playAction();
        }
      }
    }*/
  };

  render() {

    const {
      isPlaying,
      analyser,
      currentTime,
      editor,
      scrollerOffsetX,
      isLoading,
    } = this.state;

    const { data } = this.props;

    const callbackConfig = this.callbackConfigHandle.bind(this);
    const colorDisplayHandle = this.colorDisplayHandle.bind(this);

    return (
      <div>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <MenuTop
              analyser={analyser}
              controlHandle={this.controlHandle.bind(this)}
              isPlaying={isPlaying}
              currentTime={currentTime}
              replay={this.replay}
            />

            <Grid item xs={12}>
              <FormControlLabel
                value="1"
                control={
                  <Checkbox color="default" style={{ color: "white" }} />
                }
                label="Follow cursor during play"
                labelPlacement="end"
                style={{ color: "white" }}
                onChange={this.handleFollow.bind(this)}
              />
            </Grid>

            <Grid item xs={2}>
              <div id={"layers"} className={"list-layers"}>
                <div>
                  <Counter time={currentTime} />
                </div>
                {data.layers.map(function (item, index) {
                  return (
                    <LayerLabel
                      key={index}
                      name={item.name}
                      callbackConfig={callbackConfig}
                      colorDisplayHandle={colorDisplayHandle}
                      config={item}
                      isPlaying={isPlaying}
                    />
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={10}>
              <div id="blocks" style={{ width: "100%", float: "left" }}>
                <Timer
                  cursor={this.cursor}
                  editor={editor}
                  callback={this.updateTime.bind(this)}
                  scrollerOffsetX={scrollerOffsetX}
                  zoomIn={this.zoomIn.bind(this)}
                  zoomOut={this.zoomOut.bind(this)}
                  controlHandle={this.controlHandle.bind(this)}
                />
                <div
                  id="scroller"
                  ref={(c) => (this.blockLayer = c)}
                  style={{
                    position: "relative",
                    top: -3,
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <Layer
                    editor={editor}
                    layers={data.layers}
                    callback={this.callbackMoveBlock.bind(this)}
                    isPlaying={isPlaying}
                  />
                  <div id="cursorTimeline" ref={(c) => (this.cursor = c)} />
                </div>
              </div>
            </Grid>
            <div style={{ color: "white" }}>
              <input
                type="number"
                defaultValue={this.state.editor.duration}
                onChange={this.onChangeDuration.bind(this)}
              />{" "}
              duration
            </div>
          </Grid>
          <div
            id="color-panel"
            style={{ position: "absolute", top: 5, left: 5, display: "none" }}
          >
            <GithubPicker
              triangle="top-left"
              onChange={this.onColorChange.bind(this)}
              width={214}
              colors={pickerColors}
            />
          </div>
        </div>
      </div>
    );
  }
}
