import React, { useEffect } from "react";
import { Container, Card, CardMedia, makeStyles } from "@material-ui/core";
import useTitle from "../../Hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "calc(100%)",
  },
  media: {
    height: 0,
    paddingTop: "50%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function Components(props) {
  const title = useTitle();
  const classes = useStyles();

  useEffect(() => {
    title.setTitle("The Podcaster - Home");
  }, []);

  return (
    <Container disableGutters maxWidth={false}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={"https://picsum.photos/2048/1024"}
        >
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              marginTop: "-35%",
              width: "100%",
              /*height: "100%",*/
            }}
          >
            <div className="titleShadow" style={{ fontSize: "6em" }}>
              The Podcaster
              <br />
              <span
                style={{
                  fontSize: 24,
                  top: -50,
                  position: "relative",
                }}
              >
                Your voice is now heard all over the world and in the most
                simple way.
              </span>
            </div>
          </div>
        </CardMedia>
      </Card>
    </Container>
  );
}
