const spearkers = [
  {
    name: "Franck",
    image: "https://material-ui.com/static/images/avatar/1.jpg",
    status: 'accepted',
    mute: false,
  },
  {
    name: "Denis",
    image: "https://material-ui.com/static/images/avatar/2.jpg",
    status: 'accepted',
    mute: true,
  },
  {
    name: "Cindy",
    image: "https://material-ui.com/static/images/avatar/3.jpg",
    status: 'pending',
    mute: false,
  },
  {
    name: "Sandy",
    image: "https://material-ui.com/static/images/avatar/4.jpg",
    status: 'accepted',
    mute: false,
  },
  {
    name: "Mike",
    image: "https://material-ui.com/static/images/avatar/5.jpg",
    status: 'accepted',
    mute: true,
  },
];

export default spearkers;
