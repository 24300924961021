import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Divider,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import HomeIcon from "@material-ui/icons/Home";
import { USER_LOGOUT, USER_LOGIN, GET_CURRENT_USER } from "../constants/user";
import AuthService from "../services/auth.service";
import store from "../store/store";

const MenuList = [{ name: "Home", url: "/", icon: <HomeIcon /> }];

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}));

export default function MainMenu(props) {
  const [openUser, setOpenUser] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUser = useSelector((state) => state.user.user);
  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const loggedUSer = AuthService.getCurrentUser();
    store.dispatch({
      type: GET_CURRENT_USER,
      payload: loggedUSer,
    });
  }, []);

  const handleMenu = (e) => {
    setAnchorEl(e.target);
    setOpenUser(true);
  };

  const handleClose = () => {
    setOpenUser(false);
  };

  const handleLogout = () => {
    store.dispatch({
      type: USER_LOGOUT,
    });
    AuthService.logout();
    setOpenUser(false);
    //history.push("/");
    document.location.href = "/";
  };

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="h6" noWrap>
              The Podcaster
            </Typography>
          </Link>
          {currentUser ? (
            <div style={{ position: "absolute", right: 5 }}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <PersonIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openUser}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/my-account"}
                >
                  My Account
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/my-podcasts"}
                >
                  My Podcasts
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </React.Fragment>
  );
}
