import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core/";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import Delete from "@material-ui/icons/Delete";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Check from "@material-ui/icons/Check";
import { red, common, green } from "@material-ui/core/colors";

const Line = (props) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={props.name} src={props.image} />
      </ListItemAvatar>
      <ListItemText primary={props.name} />
      <ListItemSecondaryAction>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <IconButton size="small">
              {props.status === "pending" ? (
                <Tooltip arrow title={"Pending"}>
                  <HourglassEmptyIcon />
                </Tooltip>
              ) : (
                <Tooltip arrow title={"Accepted"}>
                  <Check style={{ color: green[400] }} />
                </Tooltip>
              )}
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton size="small">
              {props.mute ? (
                <Tooltip arrow title={"UnMute"}>
                  <MicOffIcon style={{ color: red[400] }} />
                </Tooltip>
              ) : (
                <Tooltip arrow title={"Mute"}>
                  <MicIcon style={{ color: "#AAA" }} />
                </Tooltip>
              )}
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton size="small">
              <Tooltip arrow title={"Remove"}>
                <Delete style={{ color: red[400] }} />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
export default Line;
