export function hhmmss(sec) {
  let o = new Date(0);
  o.setSeconds(sec);
  let p = new Date(sec * 1000);
  //return o.toISOString().substr(11, 8) ;
  return (
    o.toISOString().substr(11, 8) +
    "." +
    (1000 + p.getMilliseconds()).toString().substr(1)
  );
}

export function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function lumColor(color, pourcent) {
  let r = (pourcent / 100) * hexToR(color);
  r = r < 0 ? 0 : r;
  let g = (pourcent / 100) * hexToG(color);
  g = g < 0 ? 0 : g;
  let b = (pourcent / 100) * hexToB(color);
  b = b < 0 ? 0 : b;
  return { r, g, b };
}

function cutHex(h) {
  return h.charAt(0) === "#" ? h.substring(1, 7) : h;
}

function hexToR(h) {
  return parseInt(cutHex(h).substring(0, 2), 16);
}

function hexToG(h) {
  return parseInt(cutHex(h).substring(2, 4), 16);
}

function hexToB(h) {
  return parseInt(cutHex(h).substring(4, 6), 16);
}

export function hextoRGB(h) {
  return [hexToR(h), hexToG(h), hexToB(h)].join(",");
}

export function writeInt16(n, a, offset) {
  n = Math.floor(n);

  let b1 = n & 255;
  let b2 = (n >> 8) & 255;

  a[offset + 0] = b1;
  a[offset + 1] = b2;
}

export function writeInt32(n, a, offset) {
  n = Math.floor(n);
  let b1 = n & 255;
  let b2 = (n >> 8) & 255;
  let b3 = (n >> 16) & 255;
  let b4 = (n >> 24) & 255;

  a[offset + 0] = b1;
  a[offset + 1] = b2;
  a[offset + 2] = b3;
  a[offset + 3] = b4;
}

export function writeString(s, a, offset) {
  for (let i = 0; i < s.length; ++i) {
    a[offset + i] = s.charCodeAt(i);
  }
}

export function writeAudioBuffer(audioBuffer, a, offset, asFloat) {
  let n = audioBuffer.length;
  // let n = audioBuffer.reduce((a, b) => a + b.length, 0);
  let channels = audioBuffer.numberOfChannels;
  // let channels = audioBuffer.length;

  for (let i = 0; i < n; ++i) {
    for (let k = 0; k < channels; ++k) {
      let buffer = audioBuffer.getChannelData(k);
      // let buffer = audioBuffer[k];
      if (asFloat) {
        let sample = floatBits(buffer[i]);
        writeInt32(sample, a, offset);
        offset += 4;
      } else {
        let sample = buffer[i] * 32768.0;

        // Clip samples to the limitations of 16-bit.
        // If we don't do this then we'll get nasty wrap-around distortion.
        if (sample < -32768) sample = -32768;
        if (sample > 32767) sample = 32767;

        writeInt16(sample, a, offset);
        offset += 2;
      }
    }
  }
}

export function floatBits(f) {
  let buf = new ArrayBuffer(4);
  new Float32Array(buf)[0] = f;
  let bits = new Uint32Array(buf)[0];
  // Return as a signed integer.
  return bits | 0;
}

export const pickerColors = [
  "#CC2222",
  "#FF3333",
  "#FFBB00",
  "#FFFF88",
  "#88FF88",
  "#00FF00",
  "#33DD33",
  "#22CC22",
  "#2222FF",
  "#5555FF",
  "#aaaaFF",
  "#ddddFF",
  "#FF88FF",
  "#FF00FF",
  "#DD33DD",
  "#CC22CC",
];

export function gridContext(ctx, cW, cH, lineColor) {
  const nbLines = 12;
  // Vertical Lines
  for (let r = cH / nbLines; r < cH - 1; r += cH / nbLines) {
    ctx.beginPath();
    ctx.moveTo(0, r);
    ctx.lineTo(cW, r);
    ctx.strokeStyle = "rgba(" + hextoRGB(lineColor) + ",.25) ";
    ctx.stroke();
  }
  const nbLinesH = 12;
  // Horizontal Lines
  for (let r = cW / nbLinesH; r < cW - 1; r += cW / nbLinesH) {
    ctx.beginPath();
    ctx.moveTo(r, 0);
    ctx.lineTo(r, cW);
    ctx.strokeStyle = "rgba(" + lineColor + ",.25) ";
    ctx.stroke();
  }
}
