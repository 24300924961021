import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link as LinkHistory} from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Backdrop,
  Link,
  CircularProgress,
  Paper,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import { isEmail } from "validator";
import store from '../../../store/store'
import {USER_LOGIN} from '../../../constants/user';
import useTitle from "../../../Hooks/useTitle";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Podcaster
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    // backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundImage: "url(/assets/bg/bg1.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const [user, setUser] = useState(AuthService.getCurrentUser());
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [badcredential, setBadcredential] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const title = useTitle();

  useEffect(() => {
    title.setTitle("Login");
  }, []);
  

  function onChangeUsername(e) {
    setUsername(e.target.value);
  }

  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  function handleLogout() {
    AuthService.logout();
    setUser(null);
  }

  function handleLogin(e) {
    e.preventDefault();
    setUsernameError(false);
    setPasswordError(false);
    setBadcredential(false);

    if (username && password && isEmail(username)) {
      setLoading(true);
      AuthService.login(username, password).then(
        () => {
          UserService.getCurrent().then((response) => {
            let data = JSON.parse(localStorage.getItem("user"));

            let newUser = {
              ...data,
              ...response.data,
            };

            setUser(newUser);
            localStorage.setItem("user", JSON.stringify(newUser));
            history.push("/my-podcasts");
            setLoading(false);

            store.dispatch({
              type: USER_LOGIN,
              payload: newUser
            });

          });
        },
        (error) => {
          setLoading(false);
          setBadcredential(true);
        }
      );
    } else {
      setUsernameError(!username || username === "" || !isEmail(username));
      setPasswordError(!password || password === "");
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {user ? (
          <div>
            Already logged
            <button onClick={handleLogout}>logout</button>
          </div>
        ) : (
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            {badcredential ? <div>Bad credentials</div> : ""}

            <form className={classes.form} noValidate onSubmit={handleLogin}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                onChange={onChangeUsername}
                error={usernameError}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                error={passwordError}
                onChange={onChangePassword}
                autoComplete="current-password"
              />
              {/*
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/password-recovery" variant="body2" component={LinkHistory}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/sign-up" variant="body2" component={LinkHistory}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        )}
      </Grid>
    </Grid>
  );
}
