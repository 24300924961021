import React, { Component } from "react";

export default class Timer extends Component {
  canvas;
  editor;
  scroller;
  layersWidth;
  cursor;

  constructor(props) {
    super(props);
    this.editor = this.props.editor;
  }

  componentDidMount() {
    this.cursor = document.getElementById("cursorTimeline");
    this.layersWidth = document.getElementById("layers").clientWidth;
    this.scroller = document.getElementById("scroller");

    this.canvas = document.getElementById("canvasTimer");
    this.canvas.addEventListener(
      "mousedown",
      this.mouseDownHandle.bind(this),
      false
    );

    window.addEventListener(
      "resize",
      function () {
        this.updateTimer();
      }.bind(this)
    );

    this.canvas.addEventListener("wheel", this.wheelHandle.bind(this));
    this.canvas.addEventListener("DOMMouseScroll", this.wheelHandle.bind(this));

    this.updateTimer();
  }

  wheelHandle(e) {
    const delta = Math.sign(e.deltaY);
    if (delta < 0) {
      this.props.zoomIn();
    } else {
      this.props.zoomOut();
    }
  }

  mouseDownHandle(event) {
    event.preventDefault();
    const callback = this.props.callback ?? false;
    function onMouseMove(event, updateReplay) {
      const scrollLeft = document.getElementById("scroller").scrollLeft;
      callback(event.offsetX + scrollLeft, updateReplay ?? false);
    }

    function onMouseUp(event) {
      onMouseMove(event, true);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    document.addEventListener("mousemove", onMouseMove, false);
    document.addEventListener("mouseup", onMouseUp, false);
  }

  componentDidUpdate() {
    this.updateTimer();
  }

  updateTimer() {
    const duration = this.editor.duration;
    const scale = this.editor.scale;

    this.canvas.width = this.scroller.clientWidth;
    this.canvas.classname = "block-canvas";

    const context = this.canvas.getContext("2d", { alpha: false });

    context.fillStyle = "#555";
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    context.strokeStyle = "#888";
    context.beginPath();

    context.translate(-this.props.scrollerOffsetX, 0);

    const width = duration * scale;
    const scale4 = scale / 4;

    for (let i = 0.5; i <= width; i += this.editor.scale) {
      context.moveTo(i + 0, 18);
      context.lineTo(i + 0, 26);

      if (scale > 16) {
        context.moveTo(i + scale4 * 1, 22);
        context.lineTo(i + scale4 * 1, 26);
      }
      if (scale > 8) {
        context.moveTo(i + scale4 * 2, 22);
        context.lineTo(i + scale4 * 2, 26);
      }
      if (scale > 16) {
        context.moveTo(i + scale4 * 3, 22);
        context.lineTo(i + scale4 * 3, 26);
      }
    }

    context.stroke();

    context.font = "10px Arial";
    context.fillStyle = "#CCC";
    context.textAlign = "center";

    const step = Math.max(1, Math.floor(64 / scale));

    for (let i = 0; i < duration; i += step) {
      const minute = Math.floor(i / 60);
      const second = Math.floor(i % 60);

      const text = (minute > 0 ? minute + ":" : "") + ("0" + second).slice(-2);

      context.fillText(text, i * scale, 13);
    }
  }

  render() {
    return <canvas height={32} id={"canvasTimer"} />;
  }
}
