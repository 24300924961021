import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../config";

class PodcastService {
  getAll() {
    return axios.get(API_URL + "/podcasts.json", { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(API_URL + `/podcasts/${id}.json`, { headers: authHeader() });
  }

  getLayersById(id) {
    return axios.get(API_URL + `/podcasts/${id}/layers`, {
      headers: authHeader(),
    });
  }

  getSpeakersById(id) {
    return axios.get(API_URL + `/podcasts/${id}/speakers`, {
      headers: authHeader(),
    });
  }
  create(title) {
    return axios.post(
      API_URL + "/podcasts",
      {
        title,
      },
      { headers: authHeader() }
    );
  }
}

export default new PodcastService();
