import React, { Component } from "react";
import { hhmmss } from "../../../utils/tools";
import "../../../css/style.scss";

export default class Counter extends Component {
  render() {
    return (
      <div className={"center-cell counter"}>
        <strong>{hhmmss(this.props.time)}</strong>
      </div>
    );
  }
}
