import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import useTitle from "../../Hooks/useTitle";

export default function Components(props) {
  const title = useTitle();

  useEffect(() => {
    title.setTitle("About");
  }, []);

  return (
    <Container disableGutters maxWidth={false}>
      About
    </Container>
  );
}
