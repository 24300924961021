import React, { Component } from "react";
import Draggable from "react-draggable";
import Waveform from "./Waveform";
import "../../../css/style.scss";
import { ContactsOutlined } from "@material-ui/icons";

export default class Block extends Component {
  wrapper;

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      start: this.props.block.start,
    };
  }

  eventLogger = (e) => {
    const { block, layer, scale, callback } = this.props;
    const { start } = this.state;
    switch (e.type) {
      case "mouseup":
        e.target.style.cursor = "grab";
        callback(layer.id, block.id, start);
        break;
      default:
      case "mousedown":
        e.target.style.cursor = "grabbing";
        break;
      case "mousemove":
        const layers = document.getElementById("layers");
        const scroller = document.getElementById("scroller");
        const offsetBlocks = scroller.scrollLeft;
        const layersWidth = layers.clientWidth;
        const posX =
          e.x -
          e.offsetX -
          layersWidth +
          offsetBlocks -
          1.75 -
          document.querySelector(".MuiContainer-root").offsetLeft;
        const newStart = parseFloat((posX / scale).toFixed(3));
        this.setState({ start: newStart });
        break;
    }
  };

  render() {
    const { layer, block, scale, isPlaying } = this.props;
    const left = block.start * scale;
    const background = layer.color;
    const opacity = layer.audio ? 1 : 0.25;
    const width = Math.round(block.duration * scale);
    return (
      <Draggable
        axis="x"
        onStart={this.eventLogger}
        onDrag={this.eventLogger}
        onStop={this.eventLogger}
        position={{ x: left, y: 0 }}
        bounds="parent"
        disabled={isPlaying}
      >
        <div
          ref={this.wrapper}
          id={`block-${block.id}`}
          style={{ width, background, opacity }}
          className={"line center-cell block"}
        >
          {/*<div style={{position: 'absolute', width: 6, height: 30, cursor: 'w-resize'}}/>*/}
          <Waveform block={block} layer={layer} scale={scale} />
          {/*(parseFloat(start).toFixed(3)) + " / " + block.duration} /*}
          {/*<div style={{position: 'absolute', right: 0, top: 0, width: 6, height: 30, cursor: 'e-resize'}}/>*/}
        </div>
      </Draggable>
    );
  }
}
