import { v4 as uuidv4 } from "uuid";
const io = require("socket.io-client");
const RecordRTC = require("recordrtc");
const socket = io.connect("https://podcaster-node.funkylab.fr");
const ss = require("socket.io-stream");
var Peer = require("simple-peer");

// https://dev.to/jeffersonxavier/webrtc-a-simple-video-chat-with-javascript-1686

export default class Recording {
  recordAudio;
  blobs;
  uuid;
  slice;
  context;
  room;
  eventStart;
  eventStop;
  eventConnect;
  eventDisconnect;
  analyser;
  stream;
  remoteStream;
  localConnection;
  remoteConnection;
  localChannel;
  remoteChannel;

  constructor() {
    this.remoteStream = new MediaStream();
    this.eventConnect = new Event("connect");
    this.eventDisconnect = new Event("disconnect");
    this.eventStart = new Event("start");
    this.eventStop = new Event("stop");
    this.blobs = [];
    this.context = new (window.AudioContext || window.webkitAudioContext)();
    this.analyser = this.context.createAnalyser();

    this.localChannel = false;
    this.remoteChannel = false;

    // Warning this turn on the return of the audio on your computer
    // this.analyser.connect(this.context.destination);
  }
  socketInit() {
    socket.on(
      "connect",
      function () {
        document.dispatchEvent(this.eventConnect);
        console.log("Successfully connected!");
      }.bind(this)
    );

    socket.on(
      "disconnect",
      function () {
        document.dispatchEvent(this.eventDisconnect);
        console.log("Warning socket disconnected!");
      }.bind(this)
    );

    socket.on("results", function (data) {
      console.log("results", data);
    });

    socket.on("voice", function (data, stream) {
      var blob = new Blob([stream], { type: "audio/ogg; codecs=opus" });
      var audio = document.querySelector("audio");
      console.log("received voice", data);
      audio.src = window.URL.createObjectURL(blob);
    });

    // If more than 2 client's access the page
    socket.on("sessionActive", () => {
      // Just a simple message for the users
      console.log("Session Active. Please come back later.");
    });

    socket.on("other-users", this.otherUserSocket.bind(this));

    // Receive Offer From Other Client
    socket.on(
      "offer",
      function (socketId, description) {
        // Ininit peer connection
        this.remoteConnection = new RTCPeerConnection();

        // Add all tracks from stream to peer connection
        if (this.stream) {
          this.stream
            .getTracks()
            .forEach((track) => remoteConnection.addTrack(track, this.stream));
        }

        // Send Candidtates to establish a channel communication to send stream and data
        this.remoteConnection.onicecandidate = ({ candidate }) => {
          candidate && socket.emit("candidate", socketId, candidate);
        };

        // Receive stream from remote client and add to remote video area
        this.remoteConnection.ontrack = ({ streams: [stream] }) => {
          remoteVideo.srcObject = stream;
        };

        // Chanel Received
        this.remoteConnection.ondatachannel = function (channel) {
          // Store Channel
          console.log(channel);
          this.remoteChannel = channel;

          // Function Called When Receive Message in Channel
          this.remoteChannel.onmessage = (event) =>
            this.logMessage(`Receive: ${event.data}`);
          // Function Called When Channel is Opened
          this.remoteChannel.onopen = (event) =>
            this.logMessage(`Channel Changed: ${event.type}`);
          // Function Called When Channel is Closed
          this.remoteChannel.onclose = (event) =>
            this.logMessage(`Channel Changed: ${event.type}`);
        }.bind(this);

        // Set Local And Remote description and create answer
        this.remoteConnection
          .setRemoteDescription(description)
          .then(() => this.remoteConnection.createAnswer())
          .then((answer) => this.remoteConnection.setLocalDescription(answer))
          .then(() => {
            socket.emit(
              "answer",
              socketId,
              this.remoteConnection.localDescription
            );
          });
      }.bind(this)
    );

    // Receive Answer to establish peer connection
    socket.on(
      "answer",
      function (description) {
        this.localConnection.setRemoteDescription(description);
      }.bind(this)
    );

    // Receive candidates and add to peer connection
    socket.on(
      "candidate",
      function (candidate) {
        // GET Local or Remote Connection
        const conn = this.localConnection || this.remoteConnection;
        conn.addIceCandidate(new RTCIceCandidate(candidate));
      }.bind(this)
    );
  }

  otherUserSocket(otherUsers) {
    const remoteVideo = document.getElementById("remote-video");
    // Ignore when not exists other users connected
    if (!otherUsers || !otherUsers.length) return;

    const socketId = otherUsers[0];

    // Ininit peer connection
    this.localConnection = new RTCPeerConnection();
    this.logMessage(this.localConnection);
    // Add all tracks from stream to peer connection
    if (this.stream) {
      this.stream
        .getTracks()
        .forEach((track) => this.localConnection.addTrack(track, this.stream));
    }

    // Send Candidtates to establish a channel communication to send stream and data
    this.localConnection.onicecandidate = ({ candidate }) => {
      candidate && socket.emit("candidate", socketId, candidate);
    };

    // Receive stream from remote client and add to remote video area
    this.localConnection.ontrack = ({ streams: [stream] }) => {
      remoteVideo.srcObject = stream;
    };

    // Start the channel to chat
    this.localChannel = this.localConnection.createDataChannel("chat_channel");
    // Function Called When Receive Message in Channel
    this.localChannel.onmessage = (event) =>
      logMessage(`Receive: ${event.data}`);
    // Function Called When Channel is Opened
    this.localChannel.onopen = (event) =>
      this.logMessage(`Channel Changed: ${event.type}`);
    // Function Called When Channel is Closed
    this.localChannel.onclose = (event) =>
      this.logMessage(`Channel Changed: ${event.type}`);

    // Create Offer, Set Local Description and Send Offer to other users connected
    this.localConnection
      .createOffer()
      .then((offer) => this.localConnection.setLocalDescription(offer))
      .then(() => {
        socket.emit("offer", socketId, this.localConnection.localDescription);
      });
  }

  setRoom(room) {
    console.log("Enter the room", room);
    this.room = room;
  }

  recordHandle() {
    if (!this.recordAudio) {
      this.start();
    } else {
      this.stop();
    }
  }

  errorHandler(error) {
    console.error(JSON.stringify(error));
  }

  getContext() {
    return this.context;
  }

  getAnalyser() {
    return this.analyser;
  }

  logMessage = (message) => {
    const messagesEl = document.querySelector(".messages");
    const newMessage = document.createElement("div");
    newMessage.innerText = message;
    messagesEl.appendChild(newMessage);
  };

  sendMessage() {
    const messageInput = document.getElementById("message-input");
    // GET message from input
    const message = messageInput.value;
    // Clean input
    messageInput.value = "";
    // Log Message Like Sended
    this.logMessage(`Send: ${message}`);
    console.log(this);
    console.log("localChannel", this.localChannel);
    console.log("remoteChannel", this.remoteChannel);

    // GET the channel (can be local or remote)
    const channel = this.localChannel || this.remoteChannel;
    // Send message. The other client will receive this message in 'onmessage' function from channel
    channel.send(message);
  }

  streamHandle(stream) {
    this.stream = stream;

    this.recordAudio = RecordRTC(stream, {
      type: "audio",
      mimeType: "audio/webm",
      desiredSampRate: 16000,
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 1,
      timeSlice: 4000,
      checkForInactiveTracks: true,
      ondataavailable: this.prepareBlob.bind(this),
    });

    const microphone = this.context.createMediaStreamSource(stream);
    microphone.connect(this.analyser);
    this.recordAudio.startRecording();

    // Map All HTML Elements
    const videoGrid = document.getElementById("video-grid");
    const localVideo = document.getElementById("local-video");

    videoGrid.style.display = "grid";
    localVideo.srcObject = stream;

    this.socketInit();
    const sendButton = document.getElementById("message-button");
    sendButton.addEventListener(
      "click",
      function () {
        console.log("MESSAQGE", this);
        this.sendMessage();
      }.bind(this)
    );
  }

  disconnect() {
    socket.emit("disconnect");
  }

  prepareBlob(blob) {
    this.slice++;
    let name = `${this.uuid}-${this.slice}.wav`;
    this.blobs.push(blob);

    const size = blob.size;
    const stream = ss.createStream();
    ss(socket).emit("stream", stream, { name, size });
    ss.createBlobReadStream(blob).pipe(stream);
  }

  start() {
    if (!this.recordAudio) {
      this.slice = 0;
      navigator.getUserMedia(
        {
          audio: true,
          video: true,
        },
        this.streamHandle.bind(this),
        function (error) {
          console.error(JSON.stringify(error));
        }
      );
      document.dispatchEvent(this.eventStart);
    }
  }

  stop() {
    if (this.recordAudio) {
      var blob = new File(this.blobs, "audio.webm", {
        type: "audio/webm",
      });

      const audio = document.createElement("audio");
      audio.src = URL.createObjectURL(blob);

      this.prepareBlob(blob, true);
      const source = this.context.createMediaElementSource(audio);
      source.connect(this.context.destination);

      document.body.appendChild(audio);
      //this.recordAudio.stopRecording();
      socket.emit("merge-stream", { name: this.uuid, slice: this.slice });
      this.stream.stop();
      this.stream = null;
      this.recordAudio = null;

      document.dispatchEvent(this.eventStop);
    }
  }

  async listen() {
    console.log("listen room : " + this.room);
    this.start();
  }
}
