import {USER_LOGIN, USER_LOGOUT,GET_CURRENT_USER} from '../constants/user';

const initialState = {
  user: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {...state, user: action.payload};
    case USER_LOGIN:
      return {...state, user: action.payload};
    case USER_LOGOUT:
    default:
      return state
  }
}
