import React, { Component } from "react";
import "../../../css/style.scss";
import { lumColor } from "../../../utils/tools";

export default class Waveform extends Component {
  canvas;

  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  drawBuffer(canvas, buffer, color) {
    if (!color || !canvas || !buffer) {
      return;
    }
    const { r, g, b } = lumColor(color, 50);
    
    const { width, height } = canvas;
    if (!canvas.getContext) {
      return;
    }
    
    const context = canvas.getContext("2d");
    const data = buffer.getChannelData(0);
    const step = Math.ceil(data.length / width);
    const amp = height / 2;
    context.fillStyle = `rgba(${r},${g},${b},1)`;
    
    for (let i = 0; i < width; i++) {
      let min = 1.0;
      let max = -1.0;
      
      for (let j = 0; j < step; j++) {
        const datum = data[i * step + j];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      context.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
    }
  }

  render() {
    const { block, layer, scale } = this.props;
    
    const width = Math.round(block.duration * scale);
    if (block.sound && block.sound.buffer) {
      this.drawBuffer(this.canvas, block.sound.buffer, layer.color);
    }

    return <canvas width={width} height={54} ref={(c) => (this.canvas = c)} />;
  }
}
