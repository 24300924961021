import React, { useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";

const DropZone = (props) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  function handleClose() {
    setOpen(false);
  }

  function handleSave(files) {
    setOpen(false);
    setFiles(files);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip title="Click to upload an image" arrow>
        <Button
          onClick={handleOpen}
          disableRipple={true}
          style={{ margin: 10 }}
        >
          <img
            src="https://picsum.photos/480/640"
            style={{ width: "100%", maxHeight: "100%" }}
          />
        </Button>
      </Tooltip>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        showPreviews={true}
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleClose}
      />
    </div>
  );
};

export default DropZone;
