import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import podcastService from "../../../../services/podcast.service";

export default function Components(props) {
  let history = useHistory();
  const [modalNewOpen, setModalNewOpen] = useState(false);
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const { ...rest } = props;

  function handleNewPodcast() {
    console.log("open");
    setModalNewOpen(true);
  }

  function onChangeTitle(e) {
    setTitle(e.target.value);
  }

  function handleCloseModal(e) {
    e.preventDefault();
    console.log("close");
    setModalNewOpen(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTitleError(false);

    if (title && title !== "") {
      setModalNewOpen(false);
      setLoading(true);
      podcastService.create(title).then((response) => {
        history.push("/recorder/" + response.data.id);
      });
    } else {
      setTitleError(true);
    }
  }

  return (
    <div>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={modalNewOpen}
        onClose={handleCloseModal}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          New Podcast
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            What is the title of your new Podcast ?
            <br />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Your new Podcast title"
              name="title"
              onChange={onChangeTitle}
              error={titleError}
              autoFocus
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        size={"large"}
        color={"secondary"}
        onClick={handleNewPodcast}
      >
        <MicIcon /> Recorder new podcast
      </Button>
    </div>
  );
}
