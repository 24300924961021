import { useEffect, useMemo, useState } from "react";

export default function useTitle() {
  const [title, setTitle] = useState("");

  useEffect(() => {
    document.title = title;
  });

  return useMemo(() => {
    return {
      setTitle: setTitle,
    };
  }, [setTitle]);
}
