const chatMessages = [
  {
    name: "Franck",
    time: "Wed 3 March 9:31",
    author: true,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/1.jpg",
  },
  {
    name: "Michel",
    time: "Wed 3 March 9:31",
    author: false,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/2.jpg",
  },
  {
    name: "Michel",
    time: "Wed 3 March 9:31",
    author: false,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/2.jpg",
  },
  {
    name: "Michel",
    time: "Wed 3 March 9:31",
    author: false,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/2.jpg",
  },
  {
    name: "Denis",
    time: "Wed 3 March 9:31",
    author: false,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/3.jpg",
  },
  {
    name: "Denis",
    time: "Wed 3 March 9:31",
    author: false,
    message: "Curabitur luctus eros molestie malesuada fermentum. Nam nec ligula et dui egestas imperdiet sit amet ut felis. Phasellus aliquam auctor mollis. Suspendisse vehicula sed enim eu blandit. Nunc hendrerit felis leo, sed placerat quam molestie at. Vivamus vel pulvinar justo, sed venenatis neque.",
    image: "https://material-ui.com/static/images/avatar/3.jpg",
  },
  {
    name: "Franck",
    time: "Wed 3 March 9:31",
    author: true,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/1.jpg",
  },
  {
    name: "Franck",
    time: "Wed 3 March 9:31",
    author: true,
    message: "Curabitur luctus eros molestie malesuada fermentum. Nam nec ligula et dui egestas imperdiet sit amet ut felis. Phasellus aliquam auctor mollis. Suspendisse vehicula sed enim eu blandit. Nunc hendrerit felis leo, sed placerat quam molestie at. Vivamus vel pulvinar justo, sed venenatis neque.",
    image: "https://material-ui.com/static/images/avatar/1.jpg",
  },
  {
    name: "Michel",
    time: "Wed 3 March 9:31",
    author: false,
    message: "rea orueaior uaeoir uoiaeur oieaur oiaeu roiu",
    image: "https://material-ui.com/static/images/avatar/2.jpg",
  },
];

export default chatMessages;